* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}


html body {
	font-family: 'Open Sans',sans-serif;
	background-color: hsl(200, 18%, 26%);
	font-weight: 300;
}

.bg-dark {
	background-color: #37474f!important;
}

/* About Me CSS*/
.aboutme-myContainer{
	position: relative;
	width: 60%;
	margin: 9.5rem auto;
}

.intro-mySelf{

	margin: 1.5rem auto;
}

.font-s-w {
	font-size: 48px;
	font-weight: 100;
}

.logo {
	width: 300px;
	height: 300px;
	margin: auto;
	border-radius: 50%;
}

.container-content{
	color: #fff;
	text-align: center;
}

.name {
	color: #ff6f00;
}
.content-aboutMe p {
	color: #ff6f00;
	margin-top: 1rem;
	margin-bottom: 1.5rem;
}

.social-m{
	margin: 0rem auto 2.5rem auto;
}


.social-m a {
	color: #ff6f00;
}


.icon {
	margin: 0rem 1.8rem 0rem 0rem;
	font-size: 32px;
}

.linkBox {
	background-color: #37474f;
	color: #ff6f00;
	padding: 7.5px 25px;
	text-align: center;
	display: inline-block;
	font-size: 16px;
	margin: 4px 2px;
	cursor: pointer;
	border: 1px solid #ff6f00;
	margin: auto;
}

.linkBox:hover{
	text-decoration: none;
	background:#ff6f00;
	color: #fff;
}


/* Projects */

.projects-container {

	position: relative;
	width: 75%;
	margin: 9.5rem auto;
}

.projects-container h1 {
	display: block;
	text-align: center;
	color: #ff6f00;
	font-size: 50px;
	margin-bottom: 2.5rem;
}


.mycol {

	padding: 2;
	
}

.in-progress-content {
	display: block;
    text-align: center;
		color: #ff6f00;
}

.in-progress-content .text-primary {
	color: #ff6f00 !important;
}

.new-projects-label {
	margin-right: 1rem;
}

.about-project {
text-align: center;
color: #ff6f00;
margin-top: 1rem;
}


/* Skills CSS*/
.skills-container{

	position: relative;
	width: 86%;
	margin: 9.5rem auto;
}

.skills-container h1 {
	display: block;
  text-align: center;
  color: #ff6f00;
	font-size: 50px;
	margin-bottom: 2.5rem;
}

.skills-title{
	font-size: 20px;
	text-decoration: underline;
	color: #ff6f00;
	text-align: center;
	margin-bottom: 1.5rem;
}

.item {
	color: #fff;
	margin-left: 0.8rem;
}

.front-end p {
	color: #fff;
	padding-bottom: 1rem;
	border-bottom: 1px solid #ff6f00;
	text-align: center;
}

.back-end p {

	color: #fff;
	padding-bottom: 1rem;
	border-bottom: 1px solid #ff6f00;
	text-align: center;
}

.programming-language p {

	color: #fff;
	padding-bottom: 1rem;
	border-bottom: 1px solid #ff6f00;
	text-align: center;
}



/* Contact CSS*/
.myContainer {
  position: relative;
	width: 60%;
	margin: 9.5rem auto;
}

.form-container {

	width: inherit;
	margin: auto;
}

.form-container h1 {

	display: block;
  text-align: center;
  color: #ff6f00;
	font-size: 50px;
	margin-bottom: 2rem;
}

form .send-btn {

	width: 100%;
	background: #ff6f00;
	border-color: #ff6f00;
	margin-top: 20px;
}

.btn:hover {

	background: #ff6f00;
	border-color: #ff6f00;
}

.send-btn:focus {

	background: #ff6f00;
	border-color: #ff6f00;
	box-shadow: inset 0 1px 1px #ff6f00, 0 0 8px #ff6f00;
}
.btn:active {

	background: #ff6f00;
	border-color: #ff6f00;
	box-shadow: inset 0 1px 1px #ff6f00, 0 0 8px #ff6f00;
}

.custome-inputField {

	background: #37474f;
	border-color: #e98c45;
	color: #fff;
}

.form-control:focus {
    
	background: #37474f;
	color: #fff;
	border-color: #ff6f00;
	box-shadow: inset 0 1px 1px #ff6f00, 0 0 8px #ff6f00;
} 

form label {
	color: #fff;
}

.footer {

	text-align: center;
	color: #ff6f00;
}




/*********************************[RESPONSIVE CSS]**********************************/


@media only screen and  (max-width:320px)  {
	/* smartphones, iPhone, portrait 480x320 phones */ 
	/* About Me CSS*/
	.aboutme-myContainer{
		position: relative;
		width: 100%;
		margin: 6.5rem auto;
	}
	.intro-mySelf{

		margin: 1.5rem auto;
	}

	.font-s-w {
		font-size: 40px;
		font-weight: 100;
	}

	.logo {
		width: 250px;
		height: 250px;
		margin: auto;
		border-radius: 50%;
	}

	.container-content{
		color: #fff;
		text-align: center;
	}

	.name {
		color: #ff6f00;
	}

	.content-aboutMe p {
		color: #ff6f00;
		margin-top: 1rem;
		margin-bottom: 1.5rem;
		padding: 0 26px;
	}

	.social-m{
		margin: 0rem auto 2.5rem auto;
	}
	
	.social-m a {
		color: #ff6f00;
	}
	
	.icon {
		margin: 0rem 1.8rem 0rem 0rem;
		font-size: 32px;
	}
	
	.linkBox {
		background-color: #37474f;
		color: #ff6f00;
		padding: 7.5px 25px;
		text-align: center;
		display: inline-block;
		font-size: 16px;
		margin: 4px 2px;
		cursor: pointer;
		border: 1px solid #ff6f00;
		margin: auto;
	}
	
	.linkBox:hover{
		text-decoration: none;
		background:#ff6f00;
		color: #fff;
	}

 /* Projects */
 .projects-container {

	 position: relative;
	 width: 90%;
	 margin: 6.5rem auto;
 }

 .mycol {
	 padding-bottom: 2rem;
	 margin: 10px 0;
 }


	 /* Skills CSS*/
 .skills-container{
	 position: relative;
	 width: 82%;
	 margin: 6.5rem auto;
 }

 .skills-title{
	 font-size: 25px;
	 text-decoration: underline;
	 color: #ff6f00;
	 text-align: center;
	 margin-bottom: 1.5rem;
 }

 .front-end p {
	 color: #fff;
	 padding-bottom: 1rem;
	 border-bottom: 1px solid #ff6f00;
	 text-align: center;
 }

 .back-end p {

	 color: #fff;
	 padding-bottom: 1rem;
	 border-bottom: 1px solid #ff6f00;
	 text-align: center;
	 margin-bottom: 1rem;
 }
 
 .programming-language p {
 
	 color: #fff;
	 padding-bottom: 1rem;
	 border-bottom: 1px solid #ff6f00;
	 text-align: center;
 }
 
 .skills-container .row .col-sm-4 {
	 margin-bottom: 26px;
 }

 .myContainer {
	position: relative;
	width: 95%;
	margin: 6.5rem auto 1.5rem auto;
}


}

@media (max-width:481px)  { 
 /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ 

 	/* About Me CSS*/
	 .aboutme-myContainer{
		position: relative;
		width: 100%;
		margin: 6.5rem auto;
	}
	.intro-mySelf{

		margin: 1.5rem auto;
	}

	.font-s-w {
		font-size: 40px;
		font-weight: 100;
	}

	.logo {
		width: 250px;
		height: 250px;
		margin: auto;
		border-radius: 50%;
	}

	.container-content{
		color: #fff;
		text-align: center;
	}

	.name {
		color: #ff6f00;
	}

	.content-aboutMe p {
		color: #ff6f00;
		margin-top: 1rem;
		margin-bottom: 1.5rem;
		padding: 0 26px;
	}

	.social-m{
		margin: 0rem auto 2.5rem auto;
	}
	
	.social-m a {
		color: #ff6f00;
	}
	
	.icon {
		margin: 0rem 1.8rem 0rem 0rem;
		font-size: 32px;
	}
	
	.linkBox {
		background-color: #37474f;
		color: #ff6f00;
		padding: 7.5px 25px;
		text-align: center;
		display: inline-block;
		font-size: 16px;
		margin: 4px 2px;
		cursor: pointer;
		border: 1px solid #ff6f00;
		margin: auto;
	}
	
	.linkBox:hover{
		text-decoration: none;
		background:#ff6f00;
		color: #fff;
	}

	/* Projects */
	.projects-container {

		position: relative;
		width: 90%;
		margin: 6.5rem auto;
	}
 
	.mycol {
		padding-bottom: 2rem;
		margin: 10px 0;
	}
 
 
		/* Skills CSS*/
	.skills-container{
		position: relative;
		width: 82%;
		margin: 6.5rem auto;
	}
 
	.skills-title{
		font-size: 25px;
		text-decoration: underline;
		color: #ff6f00;
		text-align: center;
		margin-bottom: 1.5rem;
	}
 
	.front-end p {
		color: #fff;
		padding-bottom: 1rem;
		border-bottom: 1px solid #ff6f00;
		text-align: center;
	}
 
	.back-end p {
		color: #fff;
		padding-bottom: 1rem;
		border-bottom: 1px solid #ff6f00;
		text-align: center;
	}
	
	.programming-language p {
		color: #fff;
		padding-bottom: 1rem;
		border-bottom: 1px solid #ff6f00;
		text-align: center;
	} 
	
	.skillsSections p {
		color: #fff;
		padding-bottom: 1rem;
		border-bottom: 1px solid #ff6f00;
		text-align: center;
	}
	
	.skills-container .row .col-sm-4 {
		margin-bottom: 26px;
	}

	.myContainer {
		position: relative;
		width: 85%;
		margin: 6.5rem auto 1.5rem auto;
	}
}

@media only screen and  (max-width:640px)  { 
/* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ 
 	/* About Me CSS*/
	 .aboutme-myContainer{
		position: relative;
		width: 100%;
		margin: 6.5rem auto;
	}
	.intro-mySelf{

		margin: 1.5rem auto;
	}

	.font-s-w {
		font-size: 40px;
		font-weight: 100;
	}

	.logo {
		width: 250px;
		height: 250px;
		margin: auto;
		border-radius: 50%;
	}

	.container-content{
		color: #fff;
		text-align: center;
	}

	.name {
		color: #ff6f00;
	}

	.content-aboutMe p {
		color: #ff6f00;
		margin-top: 1rem;
		margin-bottom: 1.5rem;
		padding: 0 26px;
	}

	.social-m{
		margin: 0rem auto 2.5rem auto;
	}
	
	.social-m a {
		color: #ff6f00;
	}
	
	.icon {
		margin: 0rem 1.8rem 0rem 0rem;
		font-size: 32px;
	}
	
	.linkBox {
		background-color: #37474f;
		color: #ff6f00;
		padding: 7.5px 25px;
		text-align: center;
		display: inline-block;
		font-size: 16px;
		margin: 4px 2px;
		cursor: pointer;
		border: 1px solid #ff6f00;
		margin: auto;
	}
	
	.linkBox:hover{
		text-decoration: none;
		background:#ff6f00;
		color: #fff;
	}

 /* Projects */
 .projects-container {

	 position: relative;
	 width: 90%;
	 margin: 6.5rem auto;
 }

 .mycol {
	 padding-bottom: 2rem;
	 margin: 10px 0;
 }


	 /* Skills CSS*/
 .skills-container{
	 position: relative;
	 width: 82%;
	 margin: 6.5rem auto;
 }

 .skills-title{
	 font-size: 25px;
	 text-decoration: underline;
	 color: #ff6f00;
	 text-align: center;
	 margin-bottom: 1.5rem;
 }

 .front-end p {
	 color: #fff;
	 padding-bottom: 1rem;
	 border-bottom: 1px solid #ff6f00;
	 text-align: center;
 }

 .back-end p {

	 color: #fff;
	 padding-bottom: 1rem;
	 border-bottom: 1px solid #ff6f00;
	 text-align: center;
	 margin-bottom: 1rem;
 }
 
 .programming-language p {
 
	 color: #fff;
	 padding-bottom: 1rem;
	 border-bottom: 1px solid #ff6f00;
	 text-align: center;
 }

 .skills-container .row .col-sm-4 {
	 margin-bottom: 26px;
 }

	.myContainer {
		position: relative;
		width: 85%;
		margin: 6.5rem auto 1.5rem auto;
	}

}


.confirm {
	text-align: center;
	margin-bottom: 10rem;
}

.send-confirm-logo {
	margin-bottom: 4rem;

}

.send-confirm-logo .icon{
	font-size: 85px;
	color: #ff6f00;
}

.thanks {
	font-size: 24px;
  font-weight: 700;
}

.content-aboutMe .thanks-message {
	font-size: 20px;
}


.invalid-feedback {
	display: none;
	width: 100%;
	margin-top: .25rem;
	font-size: 17px;
	color: #ff061e;
}






@media (max-width:961px)  { 

	/* tablet, landscape iPad, lo-res laptops ands desktops */ 

	 /* Skills CSS*/
	 .skills-container{
		position: relative;
		width: 82%;
		margin: 6.5rem auto;
	}
 
	.skills-title{
		font-size: 25px;
		text-decoration: underline;
		color: #ff6f00;
		text-align: center;
		margin-bottom: 1.5rem;
	}
 
	.front-end p {
		color: #fff;
		padding-bottom: 1rem;
		border-bottom: 1px solid #ff6f00;
		text-align: center;
	}
 
	.back-end p {
 
		color: #fff;
		padding-bottom: 1rem;
		border-bottom: 1px solid #ff6f00;
		text-align: center;
		margin-bottom: 1rem;
	}
	
	.programming-language p {
	
		color: #fff;
		padding-bottom: 1rem;
		border-bottom: 1px solid #ff6f00;
		text-align: center;
	}
	
	.skills-container .rogw .col-sm-4 {
		margin-bottom: 26px;
	}

	.myContainer {
		position: relative;
		width: 85%;
		margin: 6.5rem auto 1.5rem auto;
	}
}


@media (min-width:1025px) { 
/* big landscape tablets, laptops, and desktops */ 
}

@media (min-width:1281px) {
 /* hi-res laptops and desktops */ 
}

@media only screen and (max-width: 1091px) {

 /*About Me*/

 /* Projects */

 /* Skills*/


 /* Contact 
 .contact {

	 width: 71%;
	 margin: 5rem auto;
 }

 .form-container {

	 width: 100%;
	 margin: auto;
 }

 .form-container h1 {

	 display: block;
	 text-align: center;
	 color: #ff6f00;
	 font-size: 45px;
	 margin-bottom: 2rem;
 }*/
}